<template>
  <el-form :model="directiveForm" :rules="rules" ref="directiveForm" label-width="100px" class="directiveForm">
    <el-form-item label="类型" prop="type_id">
      <el-select v-model="directiveForm.type_id" placeholder="请选择类型">
        <el-option :label="d.type_name" :value="d.id" v-for="d in directiveTypesList" :key="d.id"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="发送方式" prop="sendType">
      <el-select v-model="directiveForm.send_type" placeholder="请选择类型">
        <!-- <el-option label="TCP/IP协议" value="tcpip"></el-option>
        <el-option label="UDP" value="udp"></el-option> -->
        <el-option v-for="item in send_type_options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="指令名称" prop="instruction_name" :required="true">
      <el-input v-model="directiveForm.instruction_name" placeholder="请输入指令名称"></el-input>
    </el-form-item>
    <el-form-item :label="directiveForm.send_type === 'http' ? '请求地址' : '指令码'" prop="instruction_code" :required="true">
      <el-input v-model="directiveForm.instruction_code"
        :placeholder="directiveForm.send_type === 'http' ? '请输入请求地址' : '请输入指令码'"></el-input>
    </el-form-item>
    <el-form-item label="指令回码" prop="return_code">
      <el-input v-model="directiveForm.return_code" placeholder="请输入指令回码"></el-input>
    </el-form-item>

    <el-form-item v-if="directiveForm.send_type !== 'http'" label="指令进制" prop="unicode">
      <el-select v-model="directiveForm.unicode" placeholder="请选择活动区域">
        <el-option label="字符串" value="1"></el-option>
        <el-option label="16进制" value="2"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item v-if="directiveForm.send_type === 'http'" label="请求方式" prop="req_modes">
      <el-select v-model="directiveForm.req_modes" placeholder="请选择请求方式">
        <el-option v-for="item in req_modes_options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="directiveForm.send_type === 'http'" label="请求头" prop="req_headers">
      <div class="table-div">
        <el-table :data="directiveForm.req_headers" border style="width: 100%">
          <el-table-column v-for="cell in editableCells" :key="cell.key" :prop="cell.key" :min-width="cell.width"
            :label="cell.label">
            <template slot-scope="scope">
              <el-button v-if="cell.key === 'oper'" @click="removeDatas('req_headers', scope.$index)">删除</el-button>
              <el-input v-else :value="scope.row[cell.key]" :placeholder="cell.placeholder" @input="
                (v) => {
                  onTableCellChange(v, scope.row, cell.key, 'req_headers');
                }
              "></el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="addDatas('req_headers')" class="table-add-btn">添加</el-button>
      </div>
    </el-form-item>

    <el-form-item v-if="directiveForm.send_type === 'http'" label="请求体类型" prop="param_type">
      <el-radio-group v-model="directiveForm.param_type">
        <el-radio label="1" name="param_type">键值对</el-radio>
        <el-radio label="0" name="param_type">json格式</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="directiveForm.send_type === 'http' && directiveForm.param_type === '1'" label="请求体"
      prop="req_body">
      <div class="table-div">
        <el-table :data="directiveForm.req_body" border style="width: 100%">
          <el-table-column v-for="cell in editableCells" :key="cell.key" :prop="cell.key" :min-width="cell.width"
            :label="cell.label">
            <template slot-scope="scope">
              <el-button v-if="cell.key === 'oper'" @click="removeDatas('req_body', scope.$index)">删除</el-button>
              <el-input v-else :value="scope.row[cell.key]" :placeholder="cell.placeholder" @input="
                (v) => {
                  onTableCellChange(v, scope.row, cell.key, 'req_body');
                }
              "></el-input>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="addDatas('req_body')" class="table-add-btn">添加</el-button>
      </div>
    </el-form-item>
    <el-form-item v-if="directiveForm.send_type === 'http' && directiveForm.param_type === '0'" label="请求体"
      prop="req_body_json">
      <el-input type="textarea" placeholder="json格式" v-model="directiveForm.req_body_json"></el-input>
    </el-form-item>

    <el-form-item v-if="directiveForm.send_type !== 'http'" label="加入回车/空格" prop="enter_space">
      <el-radio-group v-model="directiveForm.enter_space">
        <el-radio label="0" name="type">否</el-radio>
        <el-radio label="1" name="type">回车</el-radio>
        <el-radio label="2" name="type">空格</el-radio>
        <el-radio label="3" name="type">回车+换行</el-radio>
        <el-radio label="4" name="type">换行/回车</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="备注信息" prop="remark">
      <el-input type="textarea" v-model="directiveForm.remark"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('directiveForm')">
        确认保存
      </el-button>
      <el-button @click="$router.push('/privateResource/manage')"> 取消 </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapState } from "vuex";
import { uuid, isEmpty } from '@/utils/utils';
export default {
  data() {
    let validateInstructionCode = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error(this.directiveForm.send_type === 'http' ? '请输入请求地址' : '请输入指令码'))
      } else {
        callback();
      }
    }
    let validateInstructionName = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请输入指令名称'))
      } else {
        callback();
      }
    }
    return {
      directiveForm: {
        instruction_name: "",
        instruction_code: "",
        return_code: '',
        send_type: "",
        req_modes: "",
        param_type: '1',
        req_headers: [],
        req_body_json: '',
        req_body: [],
        unicode: "",
        enter_space: "0",
        remark: "",
        type_id: "",
        type_name: "",
      },
      send_type_options: [],
      req_modes_options: [
        { label: 'GET', value: '0' },
        { label: 'POST', value: '1' }
      ],
      editableCells: [
        {
          label: "参数名",
          key: "name",
          placeholder: "请输入",
          width: "3",
        },
        {
          label: "参数值",
          key: "value",
          placeholder: "请输入",
          width: "2",
        },
        {
          label: "操作",
          key: "oper",
          width: "2",
        }
      ],
      rules: {
        instruction_code: [
          { validator: validateInstructionCode },
        ],
        instruction_name: [
        { validator: validateInstructionName }, 
        ],
        type_id: [
          { required: true, message: "请选择指令类型", trigger: "change" },
        ],
        req_modes: [
          { required: true, message: "请选择请求方式", trigger: "change" },
        ],
        param_type: [
          { required: true, message: "请选择请求体类型", trigger: "change" },
        ],
        req_headers: [
          { required: true, message: "请添加请求头", trigger: "change" },
        ],
        req_body_json: [
          { required: true, message: "请添加请求体", trigger: "change" },
        ],
        req_body: [
          { required: true, message: "请添加请求体", trigger: "change" },
        ]
      },httpRules: {
        instruction_code: [
          { required: true, message: '请输入请求地址', trigger: "blur" },
        ]
      },
    };
  },
  computed: {
    ...mapState(["directiveTypesList"]),
  },
  created() {
    this.getSendTypes();
  },
  mounted() {
    this.$store.dispatch("getDirectiveTypesAction", {});
    this.initDirectiveInfo();
  },
  methods: {
    addDatas(key) {
      this.directiveForm[key].push({
        name: '',
        value: '',
        id: uuid()
      });
    },
    removeDatas(formKey, i) {
      this.directiveForm[formKey].splice(i, 1);
    },
    onTableCellChange(v, row, valueKey, formKey) {
      const i = this.directiveForm[formKey].findIndex(
        (i) => i.id == row.id
      );
      this.directiveForm[formKey][i][valueKey] = v;
    },
    getSendTypes() {
      this.$changeLoadingState(true);
      this.$request.getTypeOptions({ type: "send_type" }).then((res) => {
        this.$changeLoadingState(false);
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        let datas = [];
        if (res && res.data && res.data.length !== 0) {
          res.data.map((item) => {
            datas.push({
              label: item.lable,
              value: item.value
            });
          });
        }
        this.send_type_options = datas;
      }).catch(() => {
        this.$changeLoadingState(false);
      });
    },
    initDirectiveInfo() {
      const { id } = this.$route.query;
      if (!id) return;
      this.$request.getDirectiveInfo({ id }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        const result = (res.data || {});
        for (const key in result) {
          let val = result[key];
          if (key === 'req_body' && result.param_type === '0') {
            // json类型的请求体
            this.directiveForm.req_body_json = val;
          } else {
            if (key === 'req_headers' || key === 'req_body') {
              // 值需要转换一下
              if (val) {
                let currentVal = JSON.parse(val);
                val = Object.keys(currentVal).reduce((arr, item) => {
                  arr.push({
                    name: item,
                    value: currentVal[item],
                    id: uuid()
                  });
                  return arr;
                }, [])
              } else {
                val = [];
              }
            }
            this.directiveForm[key] = val;
          }
        }
        if (isEmpty(result.param_type)) {
          // 默认请求类型为键值对
          this.directiveForm.param_type = '1';
        }
        console.log(this.directiveForm);
      });
    },
    submitForm() {
      this.$refs.directiveForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        // 验证请求头和请求体
        if (this.directiveForm.send_type === 'http') {
          if (this.directiveForm.param_type === '0') {
            var isNumber = !isNaN(Number(this.directiveForm.req_body_json));
            if (!isNumber) {
              try {
                JSON.parse(this.directiveForm.req_body_json);
              } catch (error) {
                this.$message.error('请求体请填写正确的json格式');
                return;
              }
            }
          } else {
            let isEmpty = false;
            for (let k = 0; k < this.directiveForm.req_headers.length; k++) {
              if (!this.directiveForm.req_headers[k].name || !this.directiveForm.req_headers[k].value) {
                isEmpty = true;
              }
            }
            if (isEmpty) {
              this.$message.error('请求头中存在空的值，请检查并补全');
              return;
            }
            let isBodyEmpty = false;
            for (let k = 0; k < this.directiveForm.req_body.length; k++) {
              if (!this.directiveForm.req_body[k].name || !this.directiveForm.req_body[k].value) {
                isBodyEmpty = true;
              }
            }
            if (isBodyEmpty) {
              this.$message.error('请求体中存在空的值，请检查并补全');
              return;
            }
          }
        }

        let req_headers = '';
        if (this.directiveForm.req_headers.length !== 0) {
          req_headers = JSON.stringify(this.directiveForm.req_headers.reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
          }, {}));
        }
        let req_body = '';
        if (this.directiveForm.param_type === '0') {
          req_body = this.directiveForm.req_body_json;
        } else {
          if (this.directiveForm.req_body.length !== 0) {
            req_body = JSON.stringify(this.directiveForm.req_body.reduce((obj, item) => {
              obj[item.name] = item.value;
              return obj;
            }, {}));
          }
        }
        const type_name = this.directiveTypesList.find(
          (item) => item.id == this.directiveForm.type_id
        ).type_name;
        const { id } = this.$route.query;
        const params = {
          ...this.directiveForm,
          type_name,
          id,
          req_headers: req_headers,
          req_body: req_body
        };
        delete params.req_body_json;
        this.$request.addOrUpdateDirective(params).then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.$message({
            message: `${id ? "修改" : "新建"}成功`,
            type: "success",
          });
          this.$router.push("/privateResource/manage");
        });
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss">
.directiveForm {
  background-color: #fff;
  padding: 24px 30% 24px 24px;

  .el-form-item__label {
    width: 30% !important;
  }

  .el-form-item__content {
    margin-left: 30% !important;

    .el-select {
      display: block;
    }
  }
}
</style>
<style lang="scss" scoped>
.table-div {
  display: flex;

  .table-add-btn {
    flex-grow: 0;
    margin-left: 10px;
    height: 32px;
  }
}
</style>